export default {
    "apiUrl": 'https://24-api.bkkauctioneers.com/admin/v1/',
    "mobileApiUrl": 'https://24-api.bkkauctioneers.com/',
    "mediaUrl": 'https://24-api.bkkauctioneers.com',
    // "apiUrl": 'https://bkk-api.cactusminds.com/admin/v1/',
    // "mobileApiUrl": 'https://bkk-api.cactusminds.com/',
    // "mediaUrl": 'https://bkk-api.cactusminds.com',
    // "apiUrl": 'http://localhost:8000/admin/v1/',
    // "mobileApiUrl": 'http://localhost:8000/',
    // "mediaUrl": 'http://localhost:8000',
}