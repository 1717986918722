<template>
    <div id="app">
        <div class="page-wrapper ice-theme sidebar-bg toggled">
            <nav id="sidebar" :class="['sidebar-wrapper', {'nav_open': this.open, 'nav_close': this.close},]">
                <div class="sidebar-content">
                    <!-- sidebar-brand  -->
                    <!-- <div class="sidebar-item sidebar-brand" style="background: #ffff;">
                        
                    </div> -->
                    <!-- sidebar-header  -->
                    <div class="sidebar-item sidebar-header d-flex flex-nowrap">
                        <div class="user-pic">
                           <!-- <img class="img-responsive img-rounded"
                                src="https://myshre.com/assets/logos/logo-violet.svg"> -->
                            <h6 style="font-size:17px;text-align: center;">BKK Auctioneers</h6>
                        </div>
                        <div class="user-info">
                            <span class="user-name">
                                <strong>{{name}}</strong>
                            </span>
                            <span class="user-role text-capitalize">{{role}}</span>
                        </div>
                    </div>
                    <div class=" sidebar-item sidebar-menu">
                        <ul>
                            <li  style="margin-top: 12px;">
                                <router-link :class="[{active: url === 'dashboard'}]" to="/dashboard">
                                    <i class="fas fa-tachometer-alt i-font"></i>
                                    <span class="menu-text">Dashboard</span>
                                </router-link>
                            </li>
                            <li >
                                <router-link :class="[{active: url === 'member-list' || url === 'member-create' || url === 'member-edit'}]" :to="{ path: 'member-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                    <i class="fas fa-address-book i-font"></i>
                                    <span class="menu-text">Member</span>
                                </router-link>
                            </li>
                            <li >
                                <router-link :class="[{active: url === 'banner-list' || url === 'banner-create' || url === 'banner-edit'}]" :to="{ path: 'banner-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                    <i class="fas fa-sliders i-font"></i>
                                    <span class="menu-text">Banner</span>
                                </router-link>
                            </li>
                            <li >
                                <router-link :class="[{active: url === 'client-list' || url === 'client-create' || url === 'client-edit'}]" :to="{ path: 'client-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                    <i class="fas fa-users i-font"></i>
                                    <span class="menu-text">Client</span>
                                </router-link>
                            </li>
                            <li >
                                <router-link :class="[{active: url === 'seller-list' || url === 'seller-create' || url === 'seller-edit'}]" :to="{ path: 'seller-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                    <i class="fa-solid fa-handshake i-font"></i>
                                    <span class="menu-text">Seller</span>
                                </router-link>
                            </li>
                            <li class="ul-title">
                                <a @click="toggleDropdown('location')" class="a-title">
                                    <i class="fas fa-location i-font"></i>
                                    <span class="menu-text">Location Mange</span>
                                    <i class="fas fa-angle-down i-font a-icon"></i>
                                </a>
                                <ul class="ul-li-bg" v-show="dropdowns.location">
                                    <li >
                                        <router-link :class="[{active: url === 'region-list' || url === 'region-create' || url === 'region-edit'}]" :to="{ path: 'region-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">Region</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'city-list' || url === 'city-create' || url === 'city-edit'}]" :to="{ path: 'city-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">City</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="ul-title">
                                <a @click="toggleDropdown('service')" class="a-title">
                                    <i class="fas fa-toolbox i-font"></i>
                                    <span class="menu-text">Service Mange</span>
                                    <i class="fas fa-angle-down i-font a-icon"></i>
                                </a>
                                <ul class="ul-li-bg" v-show="dropdowns.service">
                                    <li >
                                        <router-link :class="[{active: url === 'sell-hospitality'}]" :to="{ path: 'sell-hospitality', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">Sell Your Restaurant Business</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'buy-hospitality-list' || url === 'buy-hospitality-create' || url === 'buy-hospitality-edit'}]" :to="{ path: 'buy-hospitality-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">Buy A Restaurant Business</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'service-list' || url === 'service-create' || url === 'service-edit'}]" :to="{ path: 'service-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">Restaurant Support Links</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="ul-title">
                                <a @click="toggleDropdown('product')" class="a-title">
                                    <i class="fas fa-kitchen-set i-font"></i>
                                    <span class="menu-text">Product Mange</span>
                                    <i class="fas fa-angle-down i-font a-icon"></i>
                                </a>
                                <ul class="ul-li-bg" v-show="dropdowns.product">
                                    <li >
                                        <router-link :class="[{active: url === 'category-list' || url === 'category-create' || url === 'category-edit'}]" :to="{ path: 'category-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Category</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'product-list' || url === 'product-create' || url === 'product-edit'}]" :to="{ path: 'product-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle  i-font"></i>
                                            <span class="menu-text">Product</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li  class="ul-title">
                                <a @click="toggleDropdown('order')" class="a-title">
                                    <i class="fas fa-hand-holding-usd i-font"></i>
                                    <span class="menu-text">Order Mange</span>
                                    <i class="fas fa-angle-down i-font a-icon"></i>
                                </a>
                                <ul  class="ul-li-bg" v-show="dropdowns.order">
                                    <li >
                                        <router-link :class="[{active: url === 'order-list' || url === 'sale-order-detail' }]" :to="{ path: 'order-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: ''}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Order</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li @click="toggleDropdown('location')" class="ul-title" style="display: none;">
                                <i class="fas fa-location-dot i-font ul-font"></i><span class="menu-text ul-sub">Location Mange <i class="fas fa-angle-down ul-icon"></i></span>
                                <ul  class="ul-li-bg" v-show="dropdowns.location">
                                    <li >
                                        <router-link :class="[{active: url === 'order-list' || url === 'sale-order-detail' }]" :to="{ path: 'order-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: ''}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Region</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'order-list' || url === 'sale-order-detail' }]" :to="{ path: 'order-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: ''}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Township</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="ul-title">
                                <a @click="toggleDropdown('setting')" class="a-title">
                                    <i class="fas fa-gear i-font"></i>
                                    <span class="menu-text">Setting Mange</span>
                                    <i class="fas fa-angle-down i-font a-icon"></i>
                                </a>
                                <ul class="ul-li-bg" v-show="dropdowns.setting">
                                    <li style="display: none;">
                                        <router-link :class="[{active: url === 'category-list' || url === 'category-create' || url === 'category-edit'}]" :to="{ path: 'category-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">FAQ</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'about-us'}]" :to="{ path: 'about-us', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">About Us</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'contact-info'}]" :to="{ path: 'contact-info', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Contact Info</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'browse-info'}]" :to="{ path: 'browse-info', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Browse Info</span>
                                        </router-link>
                                    </li>
                                    <li style="display: none;">
                                        <router-link :class="[{active: url === 'product-list' || url === 'product-create' || url === 'product-edit'}]" :to="{ path: 'product-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Social</span>
                                        </router-link>
                                    </li>
                                    <li >
                                        <router-link :class="[{active: url === 'call-now-list' || url === 'call-now-create' || url === 'call-now-edit'}]" :to="{ path: 'call-now-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at'}}">
                                            <i class="fa-regular fa-circle i-font"></i>
                                            <span class="menu-text">Call Now</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <!-- sidebar-menu  -->
                </div>
                <!-- sidebar-footer  -->
                <div class="sidebar-footer">
           
                    <div>
                        <a href="#" @click="logout()">
                            <i class="fa fa-power-off"></i>
                        </a>
                    </div>
                    <div class="pinned-footer">
                        <a href="#">
                            <i class="fas fa-ellipsis-h"></i>
                        </a>
                    </div>
                </div>
            </nav>
            <div class="layer" @click="closeNav()" :class="{'layer_open': this.open}"></div>
            <!-- page-content  -->
            <main class="page-content pt-2">
                <label class="icon admin-menu" @click="openNav()" for="check-menu" style="margin-left: 2%;">
                    &#9776;
                </label>
                <!-- <label class="translate">
                    <v-google-translate  @select="googleTranslateSelectedHandler"/>
                </label> -->
                <router-view :class="routerViewClass"/>
            </main>
        </div>

    </div>
</template>
<script>
    import {
        mapActions
    } from 'vuex'
    export default {
        name: 'admin',
        data() {
            return {
                routerViewClass: 'my-custom-class',
                saleCount: '',
                transferCount: '',
                productCount: '',
                check: true,
                close: true,
                open: false,
                name: '',
                role: '',
                url: '',
                permissions: "",
                dropdowns: {
                    location: false,
                    product: false,
                    setting: false,
                    order: false,
                    service: false
                },
                languages: [
                    {
                        code: "en",
                        name: "English",
                        cname: "英语",
                        ename: "English",
                    },
                    {
                        code: "af",
                        name: "Afrikaans",
                        cname: "南非语",
                        ename: "Afrikaans",
                    },
                ]
            }
        },
        methods: {
            ...mapActions({
            }),
            googleTranslateSelectedHandler(language) {
                const { code, name, cname, ename } = language
                // todo ...
                },
            openNav: function () {
                this.open = !this.open
                console.log(this.open)
                if (this.check == true) {
                    this.close = false
                    this.open = true
                }
            },
            closeNav: function () {
                this.open = !this.open
                if (this.open == false) {
                    this.close = true
                    this.open = false
                }
            },
            toggleDropdown(dropdown) {
                this.dropdowns[dropdown] = !this.dropdowns[dropdown];
            },
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            },
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            },
            defaultMenu(){

            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
                //For Product
                if(this.url === 'category-list' || this.url === 'category-create' || this.url === 'category-edit'){
                    this.dropdowns.product = true
                }else if(this.url === 'product-list' || this.url === 'product-create' || this.url === 'product-edit'){
                    this.dropdowns.product = true
                }else{
                    this.dropdowns.product = false
                }
                //For Order
                if(this.url === 'order-list' || this.url === 'order-create' || this.url === 'order-edit'){
                    this.dropdowns.order = true
                }else{
                    this.dropdowns.order = false
                }
                //For Service
                if(this.url === 'sell-hospitality' || this.url === 'buy-hospitality-list' || this.url === 'buy-hospitality-create' || this.url === 'buy-hospitality-edit'
                    || this.url === 'service-list' || this.url === 'service-create' || this.url === 'service-edit'
                ){
                    this.dropdowns.service = true
                }else{
                    this.dropdowns.service = false
                }
                //For Setting
                if(this.url === 'about-us' || this.url === 'contact-info' || this.url === 'browse-info' || this.url === 'call-now-list' || this.url === 'call-now-create' || this.url === 'call-now-edit'){
                    this.dropdowns.setting = true
                }else{
                    this.dropdowns.setting = false
                }
                //For Location
                if(this.url === 'region-list' || this.url === 'region-create' || this.url === 'region-edit'
                    || this.url === 'city-list' || this.url === 'city-create' || this.url === 'city-edit'
                ){
                    this.dropdowns.location = true
                }else{
                    this.dropdowns.location = false
                }
            },
        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.name = user.name
            this.role = user.role
            this.permissions = user.permissions
            this.url = this.$route.name

            //For Product
            if(this.url === 'category-list' || this.url === 'category-create' || this.url === 'category-edit'){
                    this.dropdowns.product = true
                }else if(this.url === 'product-list' || this.url === 'product-create' || this.url === 'product-edit'){
                    this.dropdowns.product = true
                }else{
                    this.dropdowns.product = false
                }
                //For Order
                if(this.url === 'order-list' || this.url === 'order-create' || this.url === 'order-edit'){
                    this.dropdowns.order = true
                }else{
                    this.dropdowns.order = false
                }
                //For Service
                if(this.url === 'sell-hospitality' || this.url === 'buy-hospitality-list' || this.url === 'buy-hospitality-create' || this.url === 'buy-hospitality-edit'
                    || this.url === 'service-list' || this.url === 'service-create' || this.url === 'service-edit'
                ){
                    this.dropdowns.service = true
                }else{
                    this.dropdowns.service = false
                }
                //For Setting
                if(this.url === 'about-us' || this.url === 'contact-info' || this.url === 'browse-info' || this.url === 'call-now-list' || this.url === 'call-now-create' || this.url === 'call-now-edit'){
                    this.dropdowns.setting = true
                }else{
                    this.dropdowns.setting = false
                }
                //For Location
                if(this.url === 'region-list' || this.url === 'region-create' || this.url === 'region-edit'
                    || this.url === 'city-list' || this.url === 'city-create' || this.url === 'city-edit'
                ){
                    this.dropdowns.location = true
                }else{
                    this.dropdowns.location = false
                }
        }
    }
</script>
<style lang="scss">
    @import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
    @import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css";
    @import "../assets/css/main.css";
    @import "../assets/css/sidebar-themes.css";
    .my-custom-class {
        width: 90%;
    }
</style>