import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import env from '../enviorments'
import header from '../header'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: null,
        user: null,
    },
    getters: {

    },
    mutations: {
        mutateUser(state, user) {
            state.user = user
        },
        mutateToken(state, token) {
            state.token = token
        },
    },
    actions: {
        //Auth
        async userLogin({commit}, user) {
            const result = (await axios.post(env.apiUrl + 'login', {...user})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            window.localStorage.setItem('token', JSON.stringify(result.data.token))
            result.data.password = null,
            window.localStorage.setItem('user', JSON.stringify(result.data))
            commit('mutateToken', result.token)
            commit('mutateUser', result.data)
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Dashboard
        async dashboardAction({commit}) {
            const result = (await axios.get(env.apiUrl + 'dashboard', {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Member
        async memberListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'member-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Banner
        async bannerListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'banner-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async bannerStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'banner-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async bannerUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'banner-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async bannerDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'banner-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Client
        async clientListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'client-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async clientDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'client-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async clientStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'client-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async clientUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'client-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async clientDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'client-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Region
        async regionListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'region-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async regionDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'region-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async regionStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'region-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async regionUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'region-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async regionDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'region-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //City
        async cityListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'city-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async cityDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'city-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async cityStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'city-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async cityUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'city-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async cityDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'city-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Category
        async categorySortListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-sort-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categorySortStoreAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-sort-store', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'category-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async categoryStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'category-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async categoryUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'category-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async categoryDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'category-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Product
        async productListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'product-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async productStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'product-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async productUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'product-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async productDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'product-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //SKU
        async skuListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sku-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async skuDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sku-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async skuStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'sku-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async skuUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sku-update', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async skuDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'sku-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Order
        async orderListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderInfoAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderUpdateAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'order-status-changing', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async saleOrderDetailUpdateAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'qr-making', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Services
        async sellHospitalityDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'sell-hospitality-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async sellHospitalityUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'sell-hospitality-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async buyHospitalityListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'buy-hospitality-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async buyHospitalityDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'buy-hospitality-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async buyHospitalityStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'buy-hospitality-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async buyHospitalityUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'buy-hospitality-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async buyHospitalityDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'buy-hospitality-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async supplierServiceListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'service-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async supplierServiceDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'service-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async supplierServiceStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'service-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async supplierServiceUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'service-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async supplierServiceDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'service-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Setting
        async aboutUsDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'about-us', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async aboutUsUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'about-us-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async contactInfoDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'contact-info', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async contactInfoUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'contact-info-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async browseInfoDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'browse-info', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async browseInfoUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'browse-info-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        //Seller
        async sellerListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'seller-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async sellerDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'seller-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async sellerStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'seller-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async sellerUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'seller-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async sellerDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'seller-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        //Call Now
        async callNowListAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'call-now-list', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async callNowDetailAction({commit}, option) {
            const result = (await axios.post(env.apiUrl + 'call-now-detail', {...option}, {headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
        async callNowStoreAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'call-now-store',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async callNowUpdateAction({commit}, option) {
            try {
                // Check if option is a FormData object
                if (!(option instanceof FormData)) {
                    throw new Error('Option is not a FormData object');
                }
        
                // Proceed with axios POST request
                const result = await axios.post(
                    env.apiUrl + 'call-now-update',
                    option,
                    {
                        headers: {
                            ...header.getToken(),
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
        
                return { "status": "success", "data": result.data, "message": "Success", "error": [] };
            } catch (error) {
                console.error('Error in Store Action:', error);
                return { "status": "fail", "data": [], "message": error.message, "error": [error] };
            }
        },
        async callNowDeleteAction({commit}, option) {
            const result = (await axios.get(env.apiUrl + 'call-now-delete', {params: {...option}, headers: header.getToken()})).data
            if (result.status == false) return {"status": "fail", "data": [], "message": result.message, "error": []}
            return {"status": "success", "data": result.data, "message": "Success", "error": []}
        },
    },
    modules: {
    }
})
